import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { EFormSubmissionCommentType } from '@modules/forms/types';
import ProfileImage from '@common/components/profile-image';
import Text from '../../../../common/components/text';
import Confirm from '../../../../common/components/confirm-button';
import Attachment from '../../../../common/components/attachment';
import Icon from '../../../../common/components/icon';
import Modal from '../../../../common/components/modal';
import AsyncList from '../../../../common/components/list/async';
import ImageItem from '../../../../common/components/image-item/user';
import Dropdown from '../../../../common/components/dropdown';
// import EditForm from '../../forms/edit-comment';
import * as commentsSelector from '../../selectors/comments';

export const UnboundCommentComponent = ({
  item,
  message,
  loggedUser,
  onDelete,
  onHighFive,
  fetchHighFives,
  translateComment,
}) => {

  const { t } = useTranslation();
  const [showTranslation, setShowTranslation] = React.useState(false);
  const [isTranslating, setIsTranslating] = React.useState(false);

  if (!item) return null;

  // const [isEditing, setEditing] = useState(false);

  const createdAt = moment(item.created_at).calendar();

  const handleTranslate = async () => {
    if (showTranslation) return setShowTranslation(false);

    if (!item.translated_text || !item.translated_text[loggedUser.translation_language.locale]) {
      setIsTranslating(true);

      await translateComment(item.id);

      setShowTranslation(true);
      setIsTranslating(false);
    } else {
      setShowTranslation(true);
    }
  };

  // Should show translation if comment has been translated, else fall back to original text
  const text = ((loggedUser.translation_language && showTranslation && item.translated_text)
    && item.translated_text[loggedUser.translation_language.locale])
    || item.text;

  const translationEnabled = (
    item.content_language &&
    loggedUser.translation_language &&
    item.content_language !== loggedUser.translation_language.locale &&
    !item.deleted_at
  );

  const isNote = item?.type && item?.type === EFormSubmissionCommentType.NOTE;

  return (
    <div className="Comment">
      <ProfileImage size={32} user={item.user} enablePopover={item.user?.id !== loggedUser.id} />
      <div className={`Comment__Container ${isNote ? 'Comment__Container--note' : ''}`}>
        {(item.text || item.deleted_at) && (
          <div
            className={`Comment__Content ${item.deleted_at ? 'Comment__Content--deleted' : ''} 
              ${isNote ? '!tw-bg-form-submission-note' : ''}`}
          >
            <b>{item.user?.full_name}</b>
            &nbsp;
            {item.deleted_at ? t('social:comment_comment_removed') : <Text>{text}</Text>}
          </div>
        )}
        {item.attachments.length > 0 && !item.deleted_at && (
          <div className="Comment__Attachments">
            {item.attachments.map((attachment) => (
              <Attachment
                key={attachment.id}
                item={attachment}
                maxHeight={300}
              />
            ))}
          </div>
        )}
        <span className="Comment__Info">
          {item.updated_at ? `${t('social:comment_edited')} · ${createdAt}` : createdAt}
          {!item.deleted_at && onHighFive && (
            <>
              <span className="Comment__Info__Spacer">·</span>
              <a onClick={() => onHighFive(item.id)} className={(item.has_liked && 'Comment__Info__Action--active') || undefined}>
                <Trans i18nKey="social:comment_info_high_five" />
              </a>
            </>
          )}
          {item.likes_count > 0 && onHighFive && (
            <>
              {' '}
              <Modal
                title={t('social:feed_item_message_high_fives_modal_title')}
                content={(
                  <AsyncList
                    containerClassName="ItemsList"
                    data={{
                      onFetch: () => fetchHighFives(item.id),
                    }}
                    renderRow={ImageItem}
                    placeholder={t('social:feed_item_comment_high_fives_placeholder')}
                  />
                )}
              >
                <a>
                  {item.likes_count}
                  <svg
                    width="16px" height="16px" viewBox="0 0 24 24" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <defs>
                      <rect id="path-1" x="0" y="0" width="24" height="24" />
                    </defs>
                    <g id="Screens" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="Icons" transform="translate(-32.000000, -215.000000)">
                        <g id="High-five-icon" transform="translate(32.000000, 215.000000)">
                          <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1" />
                          </mask>
                          <g id="bound" />
                          <g id="high-five-icon" mask="url(#mask-2)">
                            <g transform="translate(-2.500000, -1.199997)">
                              <path
                                fillOpacity="0.3"
                                d="M17.4681818,22.1818182 C16.3802337,22.1818182 15.369311,21.8520328 14.5299271,21.2869752 C13.828268,20.8146314 13.2464741,20.1778876 12.8397518,19.4319503 C12.4318177,18.6837905 12.2,17.8257854 12.2,16.9136364 L12.2,15.8756346 L12.2,9.44442659 L12.2,8.67337676 L12.2,7.76363636 C12.2,7.15110237 12.6965569,6.65454545 13.3090909,6.65454545 C13.9216249,6.65454545 14.4181818,7.15110237 14.4181818,7.76363636 L14.4181818,10.1370944 L14.5286576,10.7752155 L15.1510501,11.7087355 L14.9727273,10.159764 L14.9727273,5.54545455 C14.9727273,4.93292055 15.4692842,4.43636364 16.0818182,4.43636364 C16.6943522,4.43636364 17.1909091,4.93292055 17.1909091,5.54545455 L17.1909091,12.2 L17.7454545,12.2 L17.7454545,3.32727273 C17.7454545,2.71473873 18.2420115,2.21818182 18.8545455,2.21818182 C19.4670794,2.21818182 19.9636364,2.71473873 19.9636364,3.32727273 L19.9636364,12.2 L20.5181818,12.2 L20.5181818,5.54545455 C20.5181818,4.93292055 21.0147387,4.43636364 21.6272727,4.43636364 C22.2398067,4.43636364 22.7363636,4.93292055 22.7363636,5.54545455 L22.7363636,12.9674846 C23.2078888,12.4720751 23.7722636,11.8935436 24.399533,11.2992883 C25.7165784,10.0515611 26.7043625,10.8400554 26.60905,11.845169 C26.5473447,12.4958798 25.0066536,15.0886106 21.9869769,19.6233612 C21.0658099,21.1562207 19.3867664,22.1818182 17.4681818,22.1818182 Z" id="hand-2" fill="#C1C1C1" transform="translate(19.407703, 12.200000) rotate(25.000000) translate(-19.407703, -12.200000) "
                              />
                              <path
                                d="M6.65223704,15.1857324 L6.65223704,7.76363636 C6.65223704,7.15110237 7.14879396,6.65454545 7.76132795,6.65454545 C8.37386195,6.65454545 8.87041886,7.15110237 8.87041886,7.76363636 L8.87041886,14.4181818 L9.42496432,14.4181818 L9.42496432,5.54545455 C9.42496432,4.93292055 9.92152123,4.43636364 10.5340552,4.43636364 C11.1465892,4.43636364 11.6431461,4.93292055 11.6431461,5.54545455 L11.6431461,14.4181818 L12.1976916,14.4181818 L12.1976916,7.76363636 C12.1976916,7.15110237 12.6942485,6.65454545 13.3067825,6.65454545 C13.9193165,6.65454545 14.4158734,7.15110237 14.4158734,7.76363636 L14.4158734,14.4181818 L14.9704189,14.4181818 L14.9704189,9.98181818 C14.9704189,9.36928419 15.4669758,8.87272727 16.0795098,8.87272727 C16.6920438,8.87272727 17.1886007,9.36928419 17.1886007,9.98181818 L17.1886007,19.1318182 C17.1886007,22.0413547 14.8299553,24.4 11.9204189,24.4 C10.8324707,24.4 9.82154807,24.0702146 8.98216416,23.505157 C8.34222288,23.0743605 7.80198757,22.5068133 7.40334057,21.8443977 C4.38222282,17.3076177 2.84080384,14.7137938 2.77908363,14.0629261 C2.68377113,13.0578125 3.67155523,12.2693182 4.98860068,13.5170455 C5.61607718,14.1114969 6.18061748,14.6902143 6.65223704,15.1857324 L6.65223704,15.1857324 Z" id="hand-1" fill="#6F6F6F" transform="translate(9.980664, 14.418182) rotate(-15.000000) translate(-9.980664, -14.418182) "
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </a>
              </Modal>
            </>
          )}
          {
            translationEnabled && (
              <>
                <span className="Comment__Info__Spacer">·</span>
                <a onClick={handleTranslate}>
                  {
                    showTranslation ?
                      <Trans i18nKey="social:feed_item_show_original" /> :
                      <Trans
                        i18nKey={
                          isTranslating ?
                            'social:feed_item_translating' :
                            'social:feed_item_show_translation'
                        }
                      />
                  }
                </a>
              </>
            )
          }
        </span>
      </div>
      {!item.deleted_at && (message.actions.can_delete_comments || (item.user?.id === loggedUser.id)) && (
        <Dropdown
          alignRight
          className="Comment__Remove"
          id={`Comment__Actions-${item.id}`}
          toggle={<Icon type="more_vert" size="small" />}
        >
          {/* {(item.user?.id === loggedUser.id) && (
            <Dropdown.Item onClick={() => setEditing(true)}><Trans i18nKey="social:comment_edit" /></Dropdown.Item>
          )} */}
          <Confirm
            danger
            title={t('social:comment_confirm_remove_title')}
            description={t('social:comment_confirm_remove_description')}
            confirmText={t('social:feed_item_message_default_confirm_remove')}
            onConfirm={() => onDelete(item.id, item.message_id)}
          >
            <Dropdown.Item danger><Trans i18nKey="social:comment_remove" /></Dropdown.Item>
          </Confirm>
        </Dropdown>
      )}
    </div>
  );
};

const mapStateToProps = () => {
  const itemSelector = commentsSelector.item();

  return (state, props) => ({
    item: itemSelector(state, props.item.id),
  });
};

const mapDispatchToProps = {
  fetchHighFives: require('../../actions').fetchCommentHighFives,
  translateComment: require('../../actions').translateComment,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnboundCommentComponent);
